//画面サイズごとの処理
var swiper;
$(window).on('load resize', function(){
	const w = $(window).width();
	deviceFunc(w);
});

const browser=browserCheck();
const os=osCheck();
const device=deviceCheck();
$("body").addClass(browser).addClass(os);

const href=$(location).attr('href');
let host=	$(location).attr('hostname');
const ref=document.referrer;
const hash=$(location).attr('hash');

if(href.indexOf("/test/")>0)	{
	host=host + "/test";
}

$(document).ready(function(){
	// ページのURLを取得
	const url = $(location).attr('href');
	// urlに「#」が含まれていれば
	if(url.indexOf("#") != -1){
		const anchor = $(location).attr('hash');
		$target = $(anchor);
		const targetOffset = $target.offset().top - $("header").height();
		$("html, body").animate({scrollTop:targetOffset}, 0);
	}
  // <a href="#***">の場合、スクロール処理を追加
  $('a[href*="#"]').click(function() {
		if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
  		let $target = jQuery(this.hash);
      $target = $target.length && $target || jQuery('[name="' + this.hash.slice(1) +'"]');
      if ($target.length) {
      	const targetOffset = $target.offset().top - $("header").height() ;
        $('html,body').animate({ scrollTop: targetOffset }, 800, 'easeOutExpo');//fast
        //$('html,body').animate({ scrollTop: targetOffset }, 1000, 'easeInOutQuart');//smooth
        /*$('html, body').animate({scrollTop: targetOffset/5,opacity: 0}, 300, function() {
					$('html, body').animate({opacity: 1,scrollTop: targetOffset}, 600);
				});//fade scroll*/
        return false;
      }
    }
  });
  
  //Menu
	var state = false;
  var pos;
  $("#menu-btn").on("click",function(){
		if (state == false) {
			pos = $(window).scrollTop();
			$('body').addClass('fixed').css({'top': -pos});
			state = true;
		} else {
			$('body').removeClass('fixed').css({'top': 0});
			window.scrollTo(0, pos);
			state = false;
		}

	  $("#menu").toggleClass("open");
  });
  
  //scroll
  /*$(window).scroll(function () {
   	const body = window.document.body;
	 	const html = window.document.documentElement;
	 	const scrTop = body.scrollTop || html.scrollTop;
	 	const scrBottom = html.scrollHeight - html.clientHeight - scrTop;
	 	if(scrTop > 300){
			
		}
		if(scrBottom >= 0){
		
		}
  });*/
  

	//コラムの処理
	if($("#am").length){
		$(".page-body table").addClass("nobreak");
		$(".page-body table").wrap('<div class="column-table-wrap"></div>');
	}
	
	//validation
	if($(".form-validation").length){
		$.extend( $.validator.messages, {
			required:'入力してください',
			email:'正しいメールアドレスの形式を入力してください',
			phone:'正しい電話番号の形式で入力してください'
		});
		var methods = {
			phone: function(value, element){
				return this.optional(element) || /^\d{9,11}$|^\d{1,4}-\d{1,4}-\d{1,4}$/.test(value);
			}
		};
		$.each(methods, function(key) {
			$.validator.addMethod(key, this);
		});
		const seminar2_validation = $(".form-validation").validate({
			rules: {
				last_name: {
					required:true,
				},
				first_name: {
					required:true,
				},
				email:{
					required:true,
					email:true,
				},
				tel:{
					required:true,
					phone:true,
				},
				q1: {
					required:true,
				},
				q2: {
					required:true,
				},
				q3:{
					required:true,
				}
			},
			messages:{
				q1:{
					required:"選択してください",
				},
				q2:{
					required:"選択してください",
				}
			},
			errorPlacement: function(error, element){
				if (element.attr("name") == "q1") {
					element.parents(".seminar-form-ifa-list").before(error);
				}else if (element.attr("name") == "q2") {
					element.parent().after(error);
				}else {
					element.after(error);
				}
			},
			submitHandler: function(e, r, t) {
				grecaptcha.ready(function() {
					grecaptcha.execute("6Le7pYUpAAAAAEqHHU_5Eyf9CJn8TfohkO80rsZF", {
						action: "submit"
					}).then(function(e) {
						const r = document.querySelector("#recaptcha_form")
						  , t = document.createElement("input");
						t.type = "hidden",
						t.name = "recaptcha_response",
						t.value = e,
						r.appendChild(t),
						r.submit()
					})
				})
			}
			
		});
	}

});

function initializeSwiper() {
	swiper = new Swiper('.swiper', {
			spaceBetween: 30,
			slidesPerView: "auto",
			scrollbar: {
				el: ".swiper-scrollbar",
				hide: false,
				draggable: true 
			},
			navigation: {
					nextEl: ".swiper-button-next",
					prevEl: ".swiper-button-prev"
			},
	});
}
// 無効化する用の記述
function destroySwiper() {
if (swiper) {
		swiper.destroy();
		swiper = undefined;
}
}

function deviceFunc(w){
	const bp = [559,959,1280];
	if(w > bp[1]){ //タブレット以上（PC）
		if($(".swiper").length){
			destroySwiper();
		}
  }else{ //タブレット以下
	
  }
  if (w > bp[0]){//スマートフォン以上
	  $(".tel-wrap .tel-number").each(function(){
			$(this).unwrap();
		});
  }else{ //スマートフォン以下
	 	$(".tel-number").each(function(){
			if(!$(this).parent(".tel-wrap").length){
				const no = $(this).data("tel");
				$(this).wrap('<a href="tel:' + no + '" class="tel-wrap"></a>');
			}
		});	
		if($(".swiper").length){
			if (!swiper) {
				initializeSwiper();
			}
		}
  }
}


/*ブラウザ判別*/
function browserCheck(){
	const ua = window.navigator.userAgent.toLowerCase();
	const av = window.navigator.appVersion.toLowerCase();
	if(ua.indexOf('edge') != -1){
		return 'edge';
	}else if (ua.indexOf('opera') != -1) {
  	return 'opera';
	}else if (ua.indexOf("msie") != -1 || ua.indexOf("trident") != -1) {
		$("body").addClass('ie');
  	if (av.indexOf("msie 6.") != -1) {
    	return 'ie6';
  	} else if (av.indexOf("msie 7.") != -1) {
    	return 'ie7';
  	} else if (av.indexOf("msie 8.") != -1) {
    	return 'ie8';
  	} else if (av.indexOf("msie 9.") != -1) {
    	return 'ie9';
  	}else if (av.indexOf("msie 10.") != -1) {
    	return 'ie10';
  	}else if (av.indexOf("rv:11.") != -1) {
    	return 'ie11';
  	}
	} else if (ua.indexOf('chrome') != -1) {
  	return 'chrome';
	} else if (ua.indexOf('safari') != -1) {
  	return 'safari';
	} else if (ua.indexOf('gecko') != -1) {
  	return 'firefox';
	} else {
  	return false;
	}
}

/*OS判別*/
function osCheck(){
	const ua = window.navigator.userAgent.toLowerCase();
	if (ua.indexOf('windows') != -1) {
		if(ua.indexOf('phone') != -1){
			return 'winphone';
		}else{
			return 'win';
		}
	}else if(ua.indexOf('iphone') != -1) {
		return 'iphone';
	}else if(ua.indexOf('ipad') != -1) {
		return 'ipad';
	}else if(ua.indexOf('mac') != -1) {
		return 'mac';
	}else if(ua.indexOf('android') != -1) {
		if(ua.indexOf('mobile') != -1){
			if(ua.indexOf('A1_07') != -1){
				return 'tablet';
			}else if(ua.indexOf('SC-01C') != -1){
				return 'tablet';
			}else{	return 'android';}
		}else{
			return 'tablet';
		}
	}else{
		return false;
	}
}

/*デバイス判別*/
function deviceCheck(){
	const ua = window.navigator.userAgent.toLowerCase();
	if((ua.indexOf("windows") != -1 && ua.indexOf("touch") != -1 && ua.indexOf("tablet pc") == -1) 
      || ua.indexOf("ipad") != -1
      || (ua.indexOf("android") != -1 && ua.indexOf("mobile") == -1)
      || (ua.indexOf("firefox") != -1 && ua.indexOf("tablet") != -1)
      || ua.indexOf("kindle") != -1
      || ua.indexOf("silk") != -1
      || ua.indexOf("playbook") != -1){
      return 'tab';
    }else if((ua.indexOf("windows") != -1 && ua.indexOf("phone") != -1)
      || ua.indexOf("iphone") != -1
      || ua.indexOf("ipod") != -1
      || (ua.indexOf("android") != -1 && ua.indexOf("mobile") != -1)
      || (ua.indexOf("firefox") != -1 && ua.indexOf("mobile") != -1)
      || ua.indexOf("blackberry") != -1){
	    return 'sp';
    }else{
      return 'pc';
    }
}